import { Button as MuiButton } from "@mui/material";
import Image, { ImageProps } from "next/image";
import { Company } from "src/apis/companies";
import { PNG } from "src/assets";
import Button, { ButtonProps } from "src/components/common/button";
import CloudWithLockIcon from "src/components/common/icon/cloud-with-lock-icon";

const ssoDirectSamlTypeTheme = {
  Rippling: {
    backgroundColor: "#FDB71C",
    icon: PNG.rippling_rrrIcon,
    name: "Rippling",
  },
} satisfies Record<
  Company["sso"]["directSamlType"],
  {
    name: string;
    backgroundColor: string;
    icon: ImageProps["src"];
  }
>;

export const SsoButton: React.FC<{
  formType: "signup" | "signin";
  type?: Company["sso"]["directSamlType"];
  loading: ButtonProps["loading"];
  // common button props
  onClick: ButtonProps["onClick"];
  disabled: ButtonProps["disabled"];
  fullWidth: ButtonProps["fullWidth"];
}> = ({ type, formType, loading, ...buttonProps }) => {
  const brandTheme = ssoDirectSamlTypeTheme[type];

  return brandTheme ? (
    <MuiButton
      size="large"
      variant="contained"
      {...buttonProps}
      sx={{
        borderRadius: 18,
        backgroundColor: brandTheme.backgroundColor,
        ":hover": {
          backgroundColor: brandTheme.backgroundColor,
        },
      }}
      startIcon={
        brandTheme.icon ? (
          <Image
            src={brandTheme.icon}
            alt={brandTheme.name}
            width={24}
            height={24}
          />
        ) : (
          <CloudWithLockIcon />
        )
      }
    >
      {formType === "signup" ? "Join Gable" : "Login"} with {brandTheme.name}
    </MuiButton>
  ) : (
    <Button
      size="large"
      variant="contained"
      color="primary"
      loading={loading}
      {...buttonProps}
      sx={{
        borderRadius: 18,
      }}
      startIcon={<CloudWithLockIcon />}
    >
      {formType === "signup" ? "Join Gable" : "Login"} with SSO
    </Button>
  );
};

import { GableGrid, GridProps } from "@gable/infra/gable-grid";
import { Divider } from "@mui/material";

export const ContentWithDividers: React.FC<{
  containerProps?: GridProps;
  leftDividerProps?: GridProps;
  rightDividerProps?: GridProps;
  children?: React.ReactNode;
}> = ({ containerProps, leftDividerProps, rightDividerProps, children }) => (
  <GableGrid container alignItems="center" {...containerProps}>
    <GableGrid item xs {...leftDividerProps}>
      <Divider />
    </GableGrid>
    <GableGrid item>{children}</GableGrid>
    <GableGrid item xs {...rightDividerProps}>
      <Divider />
    </GableGrid>
  </GableGrid>
);

import { Box, useTheme } from "@mui/material";
import { HeaderGableLogo } from "./header-gable-logo";
import { PNG } from "src/assets";
import Image from "next/image";

export const LoginSignupMobileHeader: React.FC = () => {
  const theme = useTheme();

  return (
    <Box px={2.5} py={1} position="relative">
      <Box
        bgcolor={theme.palette.veryLightPink}
        position="absolute"
        top={0}
        left={0}
        width={"100%"}
        height={"70%"}
        zIndex={"-1"}
      />
      <HeaderGableLogo />
      <Image
        src={PNG.spaceCardsCarouselPng}
        alt=""
        style={{ width: "100%", height: "auto" }}
      />
    </Box>
  );
};

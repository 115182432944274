import { ISsoSettings } from "./companies";
import api from "./root";

export async function getEmailInfo(options: { email: string }): Promise<{
  ssoEnabled: boolean;
  freeEmailDomain: boolean;
  ssoDirectProvider: ISsoSettings["directSamlType"];
}> {
  const response = await api.get(`/auth/email/info`, {
    params: { email: options.email },
  });
  return response.data;
}

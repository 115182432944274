import { HeaderContainer, HeaderContentContainer } from "./header-container";
import { HeaderGableLogo } from "./header-gable-logo";

export const LogoHeader: React.FC = () => {
  return (
    <HeaderContainer transparent>
      <HeaderContentContainer>
        <HeaderGableLogo />
      </HeaderContentContainer>
    </HeaderContainer>
  );
};

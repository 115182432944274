import { User, UserType } from "../types/auth-types";
import api from "./root";

interface LoginOptions {
  email: string;
  password: string;
}

export async function login(
  options: LoginOptions
): Promise<{ token: string; user: User }> {
  const { email, password } = options;
  const response = await api.post(`/auth/login`, {
    email,
    password,
  });
  return response.data;
}

function generateLoginUrl({
  url,
  forceAssignToCompany,
  google_oauth,
  redirectUri,
  errorRedirectUri,
  type,
  email,
}: {
  url: string;
  forceAssignToCompany?: boolean;
  google_oauth?: boolean;
  redirectUri: string;
  errorRedirectUri: string;
  type: UserType;
  email?: string;
}) {
  const searchParams = new URLSearchParams({
    redirect_uri: redirectUri,
    source: "web",
    error_redirect_uri: errorRedirectUri,
    ...(type ? { type } : {}),
    ...(google_oauth ? { google_oauth: "true" } : {}),
    ...(forceAssignToCompany ? { forceAssignToCompany: "true" } : {}),
    ...(email ? { email } : {}),
  });

  return `${url}?${searchParams.toString()}`;
}

export function loginWithSsoProvider(
  email: string,
  redirectUri: string,
  errorRedirectUri: string,
  type?: UserType
) {
  window.location.href = generateLoginUrl({
    url: "/api/app/auth/sso/initiate",
    redirectUri,
    errorRedirectUri,
    type,
    email,
  });
}

export function loginWithGoogle(
  redirectUri: string,
  errorRedirectUri: string,
  type?: UserType
) {
  window.location.href = generateLoginUrl({
    url: "/api/app/auth/sso/initiate",
    redirectUri,
    errorRedirectUri,
    type,
    google_oauth: true,
  });
}

export async function connectToSsoProvider(options: {
  returnUrl: string;
}): Promise<
  { link: string; redirect: true } | { redirect: false; link?: never }
> {
  const response = await api.put(`/sso`, options);
  return response.data;
}

interface SignupOptions {
  email: string;
  password: string;
  type?: UserType;
}

export async function signup(
  options: SignupOptions
): Promise<{ token: string; user: User }> {
  const { email, password, type } = options;
  const response = await api.post(`/auth/signup`, {
    email,
    password,
    type,
    forceAssignToCompany: true,
  });
  return response.data;
}

import { useRouter } from "next/router";
import { LoginForm } from "src/components/app/onboarding/login-form";
import { OnboardingStepFrame } from "src/components/app/onboarding/onboarding-step-frame";
import { useIsMobileWeb } from "src/components/common/utils";
import { LoginSignupMobileHeader } from "src/components/header/login-signup-mobile-header";
import { useAuthContext } from "../auth";
import { defaultPostLoginRouteConfig } from "../src/types/auth-types";

const Login = () => {
  const router = useRouter();
  const auth = useAuthContext();
  const isMobileWeb = useIsMobileWeb();

  return (
    <>
      {isMobileWeb && <LoginSignupMobileHeader />}
      <OnboardingStepFrame headTitle="Log in" pt={isMobileWeb ? 0 : undefined}>
        <LoginForm
          onLoginSuccess={() =>
            auth.performPostLoginFlow(defaultPostLoginRouteConfig)
          }
          onSignupClick={() => router.push("/signup")}
          externalLoginRedirectUri={
            typeof window === "undefined"
              ? ""
              : `${window.location.origin}/me/welcome`
          }
          externalLoginErrorRedirectUri={
            typeof window === "undefined"
              ? ""
              : `${window.location.origin}/login`
          }
        />
      </OnboardingStepFrame>
    </>
  );
};

export default Login;

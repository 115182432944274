import { useEffect, useState } from "react";
import { useGetEmailInfoQuery } from "../queries/get-email-info";

export function useGetEmailInfo(email: string, emailIsValid: boolean) {
  const [debouncedEmail, setDebouncedEmail] = useState("");

  useEffect(() => {
    if (!emailIsValid) {
      setDebouncedEmail("");
      return;
    }

    const timeoutId = setTimeout(() => {
      setDebouncedEmail(email);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [email, emailIsValid]);

  const { data: emailInfo } = useGetEmailInfoQuery(
    { email: debouncedEmail },
    !!debouncedEmail && emailIsValid
  );

  return emailInfo;
}

import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Collapse, Link, Stack, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "next-auth/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { loginWithGoogle, loginWithSsoProvider } from "src/apis/auth";
import {
  OnboardingStepLoginSignupToggle,
  OnboardingStepOrStatement,
  OnboardingStepTitle,
} from "src/components/app/onboarding/onboarding-step-frame";
import Button from "src/components/common/button";
import Typography from "src/components/common/typography";
import { useIsMobileWeb } from "src/components/common/utils";
import { OnboardingVariant } from "src/constants";
import { useGetEmailInfo } from "src/hooks/use-get-email-info";
import { NextAuthProviderName } from "src/types/next-auth-types";
import { SentryIgnoreError } from "src/utils/sentry-ignore-error";
import { z } from "zod";
import { GoogleSigninButton } from "../signin/google-signin-button";
import { SsoButton } from "./sso-button";

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(8, "Password should include at least 8 characters"),
});

const messages = {
  INCORRECT_CREDENTIALS: "Incorrect credentials",
  USER_DELETED:
    "Access has been revoked. Contact your company admin for assistance.",
  ACCOUNT_CREATED_USING_PHONE:
    "This account is associated with a phone number. Reset your password to login.",
  ACCOUNT_CREATED_USING_FACEBOOK: "Incorrect credentials",
  ACCOUNT_CREATED_USING_LINKEDIN: "Incorrect credentials",
  ACCOUNT_CREATED_USING_APPLE: "Incorrect credentials",
  ACCOUNT_CREATED_USING_DIFF_METHOD: "Incorrect credentials",
  USER_MUST_USE_SSO: "You must connect using your company's SSO.",
  Unauthorized: "Your account is blocked",
  UNAUTHORIZED: "Your account is blocked",
  user_cancelled_login: "Login cancelled",
  OAUTH_ERROR: "Authentication failed",
};

export const LoginForm: React.FC<{
  onLoginSuccess: () => void;
  onSignupClick: () => void;
  externalLoginRedirectUri: string;
  externalLoginErrorRedirectUri: string;
  onboardingVariant?: OnboardingVariant;
}> = ({
  onLoginSuccess,
  onSignupClick,
  externalLoginRedirectUri,
  externalLoginErrorRedirectUri,
  onboardingVariant,
}) => {
  const router = useRouter();
  const [ssoIsLoading, setSsoIsLoading] = useState(false);
  const isMobileWeb = useIsMobileWeb();

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onSubmit",
  });

  const emailField = useWatch<string>({
    control: form.control,
    name: "email",
  });

  const {
    mutate: handleSignin,
    status: mutationStatus,
    error: mutationError,
  } = useMutation(
    async (values: { email: string; password: string }) => {
      const result = await signIn(NextAuthProviderName, {
        redirect: false,
        email: values.email,
        password: values.password,
        flow: "login",
      });

      if (!result.ok) {
        if (result.error === "USER_MUST_USE_SSO") {
          return handleSsoLogin();
        }

        throw messages[result.error]
          ? new SentryIgnoreError(result.error)
          : new Error(result.error);
      }

      return result;
    },
    {
      onSuccess: (data) => {
        return onLoginSuccess();
      },
    }
  );

  const emailInfo = useGetEmailInfo(
    emailField,
    !!emailField && !form.errors?.email?.message
  );

  const routerQueryError = router.query.error as string;

  const handleSigninSubmit: SubmitHandler<{
    email: string;
    password: string;
  }> = async (values) => {
    handleSignin(values);
  };

  const handleSsoLogin = () => {
    loginWithSsoProvider(
      emailField,
      externalLoginRedirectUri,
      externalLoginErrorRedirectUri
    );
    setSsoIsLoading(true);
  };

  const handleGoogleLogin = () => {
    loginWithGoogle(externalLoginRedirectUri, externalLoginErrorRedirectUri);
    setSsoIsLoading(true);
  };

  const verticalGap = isMobileWeb ? 2.5 : 3.5;

  return (
    <>
      <OnboardingStepTitle
        text="Log in to Gable"
        onboardingVariant={onboardingVariant}
      />
      <OnboardingStepLoginSignupToggle
        text="Don't have an account?"
        linkText="Sign up"
        onLinkClick={onSignupClick}
        onboardingVariant={onboardingVariant}
      />
      <form onSubmit={form.handleSubmit(handleSigninSubmit)}>
        <Box display="flex" flexDirection="column">
          <Stack spacing={verticalGap} justifyContent="center">
            <TextField
              inputProps={{
                ref: form.register,
              }}
              fullWidth
              name="email"
              placeholder="Email"
              type="email"
              error={!!form.errors?.email?.message}
              helperText={form.errors?.email?.message}
            />
            <Collapse
              in={!emailInfo?.ssoEnabled}
              sx={{ width: "100%" }}
              unmountOnExit
            >
              <Stack spacing={verticalGap}>
                <Box>
                  <TextField
                    fullWidth
                    inputProps={{
                      ref: form.register,
                    }}
                    name="password"
                    placeholder="Password"
                    type="password"
                    error={!!form.errors?.password?.message}
                    helperText={form.errors?.password?.message}
                  />
                  <Box mt={1} textAlign="center">
                    <NextLink href="/password/forgot" passHref legacyBehavior>
                      <Link
                        sx={{ fontWeight: 600 }}
                        variant="body2"
                        underline="hover"
                      >
                        Forgot your password?
                      </Link>
                    </NextLink>
                  </Box>
                </Box>
                <Button
                  fullWidth={isMobileWeb}
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit"
                  loading={
                    mutationStatus === "loading" || mutationStatus === "success"
                  }
                >
                  Log in
                </Button>
                {(mutationError || routerQueryError) && (
                  <Typography textAlign="center" color="error">
                    {messages[
                      (mutationError as Error)?.message || routerQueryError
                    ] || "An error occurred"}
                  </Typography>
                )}
                <OnboardingStepOrStatement />
                <GoogleSigninButton
                  onClick={handleGoogleLogin}
                  disabled={ssoIsLoading}
                  text="Log in with Google"
                  centered
                />
              </Stack>
            </Collapse>
            <Collapse
              in={emailInfo?.ssoEnabled}
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <SsoButton
                formType="signin"
                type={emailInfo?.ssoDirectProvider}
                onClick={handleSsoLogin}
                disabled={ssoIsLoading}
                loading={ssoIsLoading}
                fullWidth={isMobileWeb}
              />
            </Collapse>
          </Stack>
        </Box>
      </form>
    </>
  );
};

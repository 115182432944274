import { useTheme } from "@mui/material/styles";
import GoogleButton from "react-google-button";

export const GoogleSigninButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
  text: string;
  centered?: boolean;
}> = ({ onClick, disabled, text, centered }) => {
  const theme = useTheme();
  return (
    <GoogleButton
      type="light"
      label={text}
      onClick={onClick}
      disabled={disabled}
      style={{
        overflow: "hidden",
        borderRadius: 15,
        width: "100%",
        fontSize: 14,
        textAlign: "left",
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: "unset",
        fontWeight: 600,
        color: theme.palette.darkBlueGrey,
        fontFamily: "'Poppins', 'Roboto'",
        display: "flex",
        alignItems: "center",
        height: 56,
        justifyContent: centered ? "center" : "flex-start",
      }}
    />
  );
};

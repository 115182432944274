import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../../auth";
import { getEmailInfo } from "../apis/email-info";

interface Options {
  email: string;
}

export function useGetEmailInfoQuery(options: Options, enabled: boolean) {
  const auth = useAuthContext();

  return useQuery(["emailInfo", options], {
    queryFn: () => getEmailInfo(options),
    enabled: !!options?.email && enabled,
  });
}
